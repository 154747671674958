export default function getValuesChangesCount( oldObj, newObj ) {
    let res = 0;

    if( !oldObj ) oldObj = {};
    if( !newObj ) newObj = {};
        
    let areDifferent = function( a,b ){
        if( !a || !b ) {
            res++; return;
        }

        let aKeys = Object.keys(a);
        let bKeys = Object.keys(b);

        if( aKeys.length == 0 && bKeys.length == 0 && a.constructor !== b.constructor ){
            res++; return;
        } 

        let keys = aKeys.length > bKeys.length ? aKeys : bKeys;
        
        for( let i = 0; i < keys.length; i++){

            let key = keys[ i ];

            if( typeof a[key] !== 'undefined' && typeof b[key] === 'undefined'  ) {
                if( typeof a[key] === 'object' ){
                    areDifferent( a[key], {} );
                }else{
                    res++;
                }
                continue;
            }
            if( typeof a[key] === 'undefined' && typeof b[key] !== 'undefined'  ) {
                if( typeof b[key] === 'object' ){
                    areDifferent( b[key], {} );
                }else{
                    res++;
                }
                continue;
            }

            if( typeof a[key] !== 'undefined' && typeof b[key] !== 'undefined' ) {

                if( typeof a[key] === 'object' && typeof b[key] === 'object' ){
                    areDifferent( a[key], b[key] );
                    continue;
                }
                
                if( typeof a[key] !== 'object' && typeof b[key] == 'object' ){
                    res++;
                    continue;
                }

                if( typeof b[key] !== 'object' && typeof a[key] == 'object' ){
                    res++;
                    continue;
                }

                if( b[key] !== a[key] ){
                    res++;
                    continue;
                }
            }
        };
    }

    areDifferent(oldObj,newObj);
    
    return res;
}