export default function getParents( elem ) {

    // Set up a parent array
    var parents = [];

    // Push each parent element to the array
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
        parents.push( elem );
    }

    // Return our parent array
    return parents;
}